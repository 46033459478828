import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, sendSignInLinkToEmail } from "firebase/auth"
import AuthLayout from "../../components/AuthLayout"
import { Button, Form, Input, notification } from "antd"
import "./signin.less"
const config = require("../../../config/site")

const SigninPage = () => {
  const auth = getAuth(app)
  const [submitSignin, setSubmitSignin] = useState(false)
  let storageUser = null
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
  }

  useEffect(() => {
    if (isBrowser) {
      if (storageUser || auth.currentUser) {
        navigate("/backend/dashboard")
        return null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const onFinishSignin = async values => {
    if (isBrowser) {
      setSubmitSignin(true)
      const { email_signin } = values
      if (email_signin) {
        const actionCodeSettings = {
          url: `${config.siteUrl}auth/dynamic/?email=${email_signin}`,
          handleCodeInApp: true,
        }
        await sendSignInLinkToEmail(auth, email_signin, actionCodeSettings)
          .then(() => {
            localStorage.setItem("emailForSignIn", email_signin)
            notification["success"]({
              message: "Check your Email",
              description: "You can now signin with email link.",
              duration: 0,
            })
          })
          .catch(error => {
            setSubmitSignin(false)
            console.log("error ", error)
            notification["error"]({
              message: error.code || error.name || error.status,
              description: error.error_description || error.message,
              duration: 0,
            })
          })
      }
    }
  }

  if (storageUser || auth.currentUser) {
    return null
  }

  return (
    <>
      <AuthLayout>
        <div className="pageContainer">
          <h2 className="title">Sign In</h2>
          <Form layout="vertical" onFinish={onFinishSignin}>
            <Form.Item
              label="Email"
              name="email_signin"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={submitSignin}
                disabled={submitSignin}
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </AuthLayout>
    </>
  )
}

export default SigninPage
